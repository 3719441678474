<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>Program Year Email</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-app id="inspire" class="">
      <template v-if="load == true">
        <v-data-table
          :headers="headers"
          :items="prgyear_list"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              >
              </v-text-field>
              <v-spacer></v-spacer>
              <span style="width: 20px"></span>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="indigo darken-4"
                    dark
                    class="mb-2"
                    v-on="on"
                    @click="iseditable = !iseditable"
                    >Add New Program Year Email</v-btn
                  >
                </template>
                <v-card>
                  <v-form>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-if="editedIndex == -1"
                        >
                          <v-autocomplete
                            class="pt-2 pb-1"
                            :items="prg_list"
                            v-model="editedItem.program"
                            label="Select Program *"
                            :rules="[(v) => !!v || 'required']"
                            persistent-hint
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" v-if="editedIndex != -1"
                          >Program
                          <v-select
                            dense
                            class="pt-2 pb-1"
                            v-model="editedItem.program"
                            :items="prg_list"
                            item-value="id"
                            persistent-hint
                          ></v-select>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-if="editedIndex == -1"
                        >
                          <v-autocomplete
                            class="pt-2 pb-1"
                            :items="year_list"
                            v-model="editedItem.year"
                            label="Select Year *"
                            :rules="[(v) => !!v || 'required']"
                            persistent-hint
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" v-if="editedIndex != -1"
                          >Year
                          <v-select
                            dense
                            v-model="editedItem.year"
                            :items="year_list"
                            item-value="id"
                            persistent-hint
                          ></v-select>
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            class="pt-2 pb-1"
                            v-model="editedItem.programyearemail"
                            label="Program Year Email  *"
                            dense
                            :rules="[(v) => !!v || 'required']"
                            persistent-hint
                          >
                          </v-text-field>
                        </v-col>
                        <v-col>
                          <input
                            type="hidden"
                            v-model="editedItem.id"
                            name="id"
                          />
                        </v-col>
                        <v-row>
                          <v-col cols="6" sm="3" md="2">
                            <v-checkbox
                              v-model="editedItem.isactive"
                              color="success"
                              label="isActive?"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" outlined text @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="primary darken-1"
                        dark
                        @click="save"
                        :disabled="valid"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.isactive="{ item }">
            <v-switch
              v-model="item.isactive"
              @click="activeswitch(item)"
            ></v-switch>
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <v-avatar style="" size="25" class="edit-avatar">
                <v-icon class="edit-v-icon mr-2" @click="editItem(item)"
                  >edit</v-icon
                >
              </v-avatar>
              <v-btn text>
                <v-icon color="red" @click="deletefun(item.id)">
                  mdi-delete</v-icon
                ></v-btn
              >
            </div>
          </template>
        </v-data-table>
      </template>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    valid: false,
    overlay: false,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    iseditable: false,
    load: false,
    isactive: true,
    dialog: false,
    prg_list: null,
    org_list: null,
    prgyear_list: [],
    year_list: null,
    errorMsg: {
      id: false,
    },
    search: "",
    headers: [
      {
        text: "Program Year Email",
        value: "programyearemail",
      },
      {
        text: "Program",
        value: "program",
      },
      {
        text: "Year",
        value: "year",
      },
      {
        text: "Active",
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],

    editedIndex: -1,
    editedItem: {
      id: 0,
      programyearemail: "",
      program: "",
      isactive: true,
      year: "",
    },
    defaultItem: {
      id: 0,
      programyearemail: "",
      program: "",
      isactive: true,
      year: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Program Year" : "Edit Program Year";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = false;
      };
    },
  },
  mounted() {
    this.onload();
  },

  methods: {
    onload() {
      axios
        .post("/AdminMaster/addProgramYear")
        .then((res) => {
          if (res.data.msg == "200") {
            //console.log(res.data.prgyear_list);
            this.prgyear_list = res.data.prgyear_list;
            this.prg_list = res.data.prg_list;
            this.year_list = res.data.year_list;
            this.load = true;
          } else {
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          //  window.console.log(error);
        });
    },
    initializeErrorMsgFalse() {
      this.errorMsg.id = false;
    },
    editItem(item) {
      // console.log(this.editedIndex);
      this.iseditable = false;
      this.editedIndex = this.prgyear_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      // console.log("after");
      // console.log(this.editedIndex);
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.initializeErrorMsgFalse();
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedItem.program == "") {
        this.showSnackbar("red", "please Select Program");
      } else if (this.editedItem.year == "") {
        this.showSnackbar("red", "please Select Year");
      } else if (this.editedItem.programyearemail == "") {
        this.showSnackbar("red", "please Enter Program Year");
      } else {
        if (this.editedIndex > -1) {
          //console.log("inedit")
          this.iseditable = false;
          axios
            .post("/AdminMaster/editProgramYear", this.editedItem)
            .then((res) => {
              // Object.assign(this.prg_list[this.editedIndex], this.editedItem);
              if (res.data.status.code == "SUCCESS") {
                this.showSnackbar("#4caf50", "Program Updated Successfully...");
              } else if (res.data.status.code == "NA") {
                this.showSnackbar("#b71c1c", "Program Year Already Present!!!"); // show snackbar on error
              }
              this.onload();
            })
            .catch((error) => {
              this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
              //window.console.log(error);
            });
        } else {
          //console.log("insave")
          axios
            .post("/AdminMaster/saveProgramYear", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                this.prg_list.push(this.editedItem);
                this.showSnackbar(
                  "#4caf50",
                  "New Program Year Added Successfully!!!"
                ); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                //alert(res.data.status.message)
                this.showSnackbar("#b71c1c", "Program Year Already Present!!!"); // show snackbar on error
              }
              this.onload();
            })
            .catch((error) => {
              window.console.log(error);
            });
        }
        this.close();
      }
    },
    deletefun(item) {
      const data = {
        deleteitem: item,
      };
      axios
        .post("/AdminMaster/deleteProgramYear", data)
        .then((res) => {
          if (res.data.msg == "200") {
            // console.log("success");
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            this.onload();
          } else {
            this.showSnackbar("#b71c1c", res.data.msg);
            //error
          }
          this.onload();
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
    activeswitch(item) {
      // console.log("abc");

      const data = {
        activeitem: item.id,
      };
      //console.log(item.id);

      axios
        .post("/AdminMaster/activeProgramYear", data)
        .then((res) => {
          //   console.log("res.data");
          //   console.log(res.data);
          if (res.data.msg == "200") {
            // console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            this.prgyear_list = res.data.prgyear_list;
            this.showSnackbar("#4caf50", res.data.message);
            //this.onLoad();
          } else {
            // console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
    isValidated() {
      if (this.editedItem.id) {
        return true;
      } else {
        if (!this.editedItem.id) {
          this.errorMsg.id = true;
        }

        return false;
      }
    }, // .....end of isValidated()
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
